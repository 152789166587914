import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import React from 'react'

import useLocalisation from '../../../helper/useLocalisation'

const HeroMobile = styled(Image)`
  display: block;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  object-position: bottom;
  @media (min-width: 1025px) {
    display: none;
  }
`

const HeroDesktop = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
  display: none;
  @media (min-width: 1025px) {
    display: block;
  }
`

const BlueBannerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 124px 0;
  margin-bottom: 32px;
  @media (max-width: 1025px) {
    margin-top: 0px;
    height: calc(100vh - 70px);
    ${(props) => (props.short ? 'height: calc(70vh - 24px - 52px);' : '')}
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5%;
  @media (max-width: 1025px) {
    height: 100%;
    width: 100%;
    padding: 0;
    justify-content: space-between;
    font-size: 0.8em;
    margin-top: 17px;
    margin-bottom: 31px;
    margin-left: 0;
    padding: 8px;
  }
`

const TextTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  @media (max-width: 1025px) {
    justify-content: space-between;
    padding-top: 27px;
    padding-bottom: 48px;
  }
`

const HeroHeading = styled.div`
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.98;
  letter-spacing: -0.61px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;
  @media (max-width: 1025px) {
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.38px;
    text-align: center;
    color: #ffffff;
  }
`

const HeroSub = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
  max-width: 370px;
`

const AbsoluteCover = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  @media (min-width: 1025px) {
    align-items: flex-start;
    justify-content: center;
  }
`

const BlueBanner = ({ image, imageDesktop, short, screenWidth }) => {
  const imageData = useStaticQuery(graphql`
    query {
      Detail1: file(
        relativePath: { eq: "carry_all/landing/header_desktop.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      Detail2: file(
        relativePath: { eq: "carry_all/landing/header_mobile.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  const Q = useLocalisation()

  return (
    <BlueBannerContainer short={short}>
      <>
        <HeroDesktop
          loading="lazy"
          fluid={imageData.Detail1.childImageSharp.fluid}
          alt=""
        />
        <HeroMobile
          loading="lazy"
          fluid={imageData.Detail2.childImageSharp.fluid}
          alt=""
        />
      </>
      <AbsoluteCover>
        <TextContainer>
          <TextTopContainer>
            <HeroHeading>{Q('a-new-companion')}</HeroHeading>
            <HeroSub>{Q('introducing-carry-all')}</HeroSub>
          </TextTopContainer>
        </TextContainer>
      </AbsoluteCover>
    </BlueBannerContainer>
  )
}

export default BlueBanner
